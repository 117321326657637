import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from 'components/Logo/Logo'

const NavigationWrapper = styled.nav`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  margin: 1.2rem 0 0;
  padding: 0.4rem 0;
`

const NavigationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 6.4rem;
`

const NavigationList = styled.ul`
  margin-left: ${({ sticky }) => (sticky ? '2.8rem' : '0')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const NavigationItem = styled.li`
  padding: ${({ sticky }) =>
    sticky ? '0 1.8rem 0 1.2rem' : '0 2.4rem 0 1.6rem'};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    top: ${({ sticky }) => (sticky ? '1.55rem' : '1.6rem')};
    right: 0;
    display: block;
    height: ${({ sticky }) => (sticky ? '0.6rem' : '0.8rem')};
    width: ${({ sticky }) => (sticky ? '0.6rem' : '0.8rem')};
    background-color: ${({ theme }) => theme.black};
    border-radius: 50%;
  }
  &:first-child {
    padding: ${({ sticky }) => (sticky ? '0 1.8rem 0 0' : '0 2.4rem 0 0')};
  }
  &:last-child::after {
    display: none;
  }
`

const NavigationLink = styled(Link)`
  padding: 0.8rem 0;
  color: ${({ theme }) => theme.black};
  font-size: ${({ sticky }) => (sticky ? '1.4rem' : '1.6rem')};
  text-decoration: none;
  font-weight: 600;
  transition: color 0.25s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`

export const items = [
  {
    slug: '/',
    name: 'About',
    partiallyActive: false,
  },
  {
    slug: '/editorial-board/',
    name: 'Editorial Board',
    partiallyActive: false,
  },
  {
    slug: '/guide-for-authors/',
    name: 'Guide for Authors',
    partiallyActive: false,
  },
  {
    slug: '/issues/',
    name: 'Issues',
    partiallyActive: true,
  },
  {
    slug: '/peer-review-process/',
    name: 'Peer-review process',
    partiallyActive: false,
  },
  {
    slug: '/ethics-statement/',
    name: 'Ethics statement',
    partiallyActive: false,
  },
]

const Navigation = props => {
  const primary = 'hsl(49, 100%, 58%)'
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    setSticky(ref.current.getBoundingClientRect().top <= 0)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const sticky = isSticky ? 1 : 0
  return (
    <NavigationWrapper ref={ref}>
      <NavigationContainer>
        {isSticky && <Logo sticky />}
        <NavigationList sticky={sticky}>
          {items.map(item => (
            <NavigationItem key={item.name} sticky={sticky}>
              <NavigationLink
                to={item.slug}
                partiallyActive={item.partiallyActive}
                activeStyle={{ color: primary }}
                sticky={sticky}
              >
                {item.name}
              </NavigationLink>
            </NavigationItem>
          ))}
        </NavigationList>
      </NavigationContainer>
    </NavigationWrapper>
  )
}

export default Navigation
