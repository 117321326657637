import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'assets/styles/globalStyles'
import { theme } from 'assets/styles/theme'
import Header from 'components/Header/Header'
import Navigation from 'components/Navigation/Navigation'
import Footer from 'components/Footer/Footer'
import rectangle from 'assets/images/rectangle.svg'

const ContentWrapper = styled.main`
  margin-top: 1.2rem;
  background-color: ${({ theme }) => theme.grey};
  /* position: relative;
  &::after {
    content: '';
    background-image: url(${rectangle});
    background-repeat: repeat-y;
    width: 542px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -271px;
    z-index: 1;
  } */
`

const MainLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header />
        <Navigation />
        <ContentWrapper>{children}</ContentWrapper>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default MainLayout
