import React from 'react'
import styled from 'styled-components'
import logo from 'assets/images/logo.svg'

const LogoWrapper = styled.div`
  padding: ${({ sticky }) => (sticky ? '0.5rem 0 0.2rem' : '1rem 0 0.4rem')};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`

const LogoImg = styled.a`
  background-image: url(${logo});
  background-size: contain;
  display: block;
  width: ${({ sticky }) => (sticky ? '2rem' : '3.5rem')};
  height: ${({ sticky }) => (sticky ? '4.2rem' : '7.35rem')};
  flex-shrink: 0;
`

const LogoTitle = styled.h1`
  margin: ${({ sticky }) => (sticky ? '0 0 0 1rem' : '0 0 0 2rem')};
  padding: ${({ sticky }) => (sticky ? '0 0 .2rem 0' : '0 0 .4rem 0')};
  color: ${({ theme }) => theme.black};
  font-weight: 700;
  font-size: ${({ sticky }) => (sticky ? '1.4rem' : '2.8rem')};
  line-height: ${({ sticky }) => (sticky ? '1.6rem' : '3.2rem')};
  flex-shrink: 0;
  &::before {
    content: '';
    width: 120%;
    height: 100%;
    background-color: ${({ theme }) => theme.primary};
    clip-path: polygon(0% 0px, 0% 100%, 100% 100%);
    z-index: -1;
    position: absolute;
    top: 0;
    left: -0.4rem;
  }
`

const Logo = ({ sticky }) => {
  return (
    <LogoWrapper sticky={sticky}>
      <LogoImg href="/" sticky={sticky} />
      <LogoTitle sticky={sticky}>
        Rural Areas
        <br />
        and Development
      </LogoTitle>
    </LogoWrapper>
  )
}

export default Logo
