import React from 'react'
import styled from 'styled-components'
import Logo from 'components/Logo/Logo'

const HeaderWrapper = styled.header`
  max-width: 1280px;
  margin: 0.4rem auto 0;
  padding: 0 6.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Issn = styled.p`
  font-weight: 600;
  font-size: 2rem;
  margin: 0 0 0 400px;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.primary};
  align-self: flex-end;
`

const Header = () => {
  return (
    <HeaderWrapper>
      <Logo />
      <Issn>ISSN 2657-4403</Issn>
    </HeaderWrapper>
  )
}

export default Header
