import React from 'react'
import styled from 'styled-components'

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.primary};
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`
const FooterCopyright = styled.p`
  margin: 0;
  padding-bottom: 1.6rem;
  font-weight: 600;
  font-size: 1.6rem;
`

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterCopyright>
        © {new Date().getFullYear()} European Rural Development Network
      </FooterCopyright>
    </FooterWrapper>
  )
}

export default Footer
